// 1. Import the initial variables
@import "bulma/sass/utilities/initial-variables";
@import "bulma/sass/utilities/functions";

@import "./variables.scss";

// 2. Set your own initial variables
// Update blue
// Add pink and its invert
$pink: #ffb3b3;
$pink-invert: #fff;

// 3. Set the derived variables
// Use the new pink as the primary color
$primary: $pink;
$primary-invert: $pink-invert;
// Use the existing orange as the danger color
$danger: $red;

// Table settings
//$table-head-background-color: $grey-light;
$table-row-hover-background-color: $blue-light;
$table-cell-border: none; //0.5px solid #f5f5f5;
$modal-background-background-color: rgba(255, 255, 255, 0.8);

// 4. Setup your Custom Colors
$linkedin: #0077b5;
$linkedin-invert: findColorInvert($linkedin);
$twitter: #55acee;
$twitter-invert: findColorInvert($twitter);
$github: #333;
$github-invert: findColorInvert($github);

// 5. Add new color variables to the color map.
@import "bulma/sass/utilities/derived-variables";
$addColors: (
  "twitter":($twitter, $twitter-invert),
  "linkedin": ($linkedin, $linkedin-invert),
  "github": ($github, $github-invert)
);
$colors: map-merge($colors, $addColors);

// 6. Import the rest of Bulma
@import "bulma";