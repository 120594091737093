@import "./variables.scss";

.App {
    //text-align: center;
    background-color: #F4F4F4;
}
  
.App-logo {
    height: 40vmin;
    pointer-events: none;
}
  
.loading {
    z-index: 1;
    padding-top: calc(50vh - 25px);
    height: 100vh;
}
  
@media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
}
  
td.has-button {
    min-width: 50px;
}
  
img{
    &.icon {
        height: 20px;
        width: 20px;
    }
    &.imageButton {
        height: 30px;
        width: 30px;
    }
}

.iconButton {
    border-radius: 0.5em;
    padding: 5px;
    max-width: 30px;
    max-height: 30px;

    &:hover {
        background-color: $blue-light;
    }

    &.deactivated {
        color: grey;

        &:hover {
            background-color: transparent;
        }
    }
}

.searchButton {
    border-radius: 0.5em;
    padding: 5px;
    max-width: 40px;
    max-height: 40px;
    display: inline;
    float: left;

    &:hover {
        background-color: $blue-light;
    }

    &.deactivated {
        color: grey;

        &:hover {
            background-color: transparent;
        }
    }
}

.addButton {
    background: $red-light;
    color: $red-dark;
    border-radius: 0.5em;
    padding: 5px;
    max-width: 40px;
    max-height: 40px;
    &:hover {
        color: #FFEBEF;
        background-color: $red;
    }
}
  
.App-link {
    color: #61dafb;
}
  
.sideNavigation {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: white;
    overflow-x: hidden;
    padding-top: 90px;
    padding-left: 20px;
    padding-right: 20px;
    border-right-width: 1px;
    border-right-color: rgba(145, 158, 171, 0.24);
    border-right-style: dashed;
    /*box-shadow: 5px 0px 10px 0px rgba(230,230,230,1);*/

    button {
        padding: 5px 20px;
        border-radius: 5px;
    }

    a {
        text-align: left;
        padding: 6px 15px;
        text-decoration: none;
        font-size: 16px;
        color: #444444;
        align-items: center;
        display: flex;
        margin-top: 5px;
        margin-bottom: 5px;

        &.selected {
            background-color: $red-light;
            color: $red;
            border-radius: 10px;
        }

        &:hover {
            background-color: $blue-light;
            border-radius: 10px;
        }
    }

    svg {
        margin-right: 10px;
        display: block;
    }

    button {
        position: fixed;
        bottom: 20px;
        left: 20px;
        width: 30px;
        height: 30px;
        text-align: center;
        svg {
            margin-left: -6px;
          }
    }
}

.smaller {
    transition-duration: 200ms, 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0ms, 0ms;
    transition-property: width, height;
}

  
.navBarElement {
    padding-left: 5px;
    padding-top: 2px;
    &.hidden {
        display: none;
    }
}
  
.appHeader {
    height: 80px;
    width: 100%;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.8);
    margin-bottom: 20px;
    position: fixed;
    z-index: 9;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    a {
        text-align: left;
        padding: 6px 20px;
        text-decoration: none;
        font-size: 16px;
        color: #444444;
        display: block;

        &:hover {
            color: #A20021;
        }

        &.add {
            display: flex;
            text-decoration: none;
            font-size: 16px;
            color: white;
            border-radius: 10px;
            background-color: $red-light;
            color: $red-dark;
            float: right;
            padding: 5px;
            &:hover {
                color: #FFEBEF;
                background-color: $red;
            }

            svg {
                margin-top: 3px;
                display: block;
            }
        }
    }
    &.smaller {
        padding-top: 15px;
        height: 60px;
    }
    button {
        text-align: left;
        padding: 6px 20px;
        text-decoration: none;
        font-size: 18px;
        color: white;
        display: block;
        border-radius: 10px;
        background-color: $red-light;
        color: $red-dark;

        &:hover {
            color: #FFEBEF;
            background-color: $red;
        }
    }
}

.appHeaderInner {
    position: fixed;
    top: 0;
    left: 30px;
    right: 70px;
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    z-index: 10;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.8);
    a {
        text-align: left;
        padding: 6px 20px;
        text-decoration: none;
        font-size: 16px;
        color: #444444;
        display: block;

        &:hover {
            color: #A20021;
        }
    }

    .searchButton {
        margin-left: 10px;
    }

    &.smaller {
        padding-top: 19px;
        height: 60px;

        &.searchActivated {
            padding-top: 25px;
            height: 90px;
        }
    }
}

.appHeaderSearch {
    position: fixed;
    top: 0;
    width: 100%;
    padding-top: 25px;
    padding-right: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    z-index: 10000;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.8);

    transform: none;
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    box-shadow: rgba(145, 158, 171, 0.16) 0px 8px 16px 0px;

    input {
        padding: 10px;
        border: 1px solid #ced4da;
        color: #495057;
        border-radius: 0.4em;
        font-size: 16px;
    }

    * {
        margin-left: 10px;
        vertical-align: middle;
    }

    button {
        padding: 6px 20px;
        border-radius: 0.5em;
    }
}

.datalist-input {
    * {
        margin-left: 0px;
    }
    width: calc(100% - 150px) !important;
}

.datalist-input-options {
    position: absolute;
    z-index: 10000;
    left: 267px;
    text-align: left;
    background-color: white;

    .datalist-input-option {
        border: 1px solid $grey-light;
        padding: 10px;
        font-size: 17px;

        &:hover {
            background-color: $red-light;
        }

        strong {
            color: black;
            display: contents;
        }
    }
}

.minimized {
    a {
        padding: 9px 10px;
    }
    svg {
        margin-right: 0 !important;
        margin-top: 0 !important;
    }
}
  
.headerNavigation {
    &:first-child {
        margin-left: 20px;
    }
    a {
        display: inline-block;
    }
}
  
.scrollable {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
}
  
.mainPage {
    background-color: #FFFFFF;
}
  
.page {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 100px;
    padding-bottom: 20px;
    margin-bottom: -25px;
    min-height: 100vh;
}
  
.box {
    padding: 20px;
    background-color: white;
    text-align: left;
    border-radius: 12px;
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0ms;
    transition-property: box-shadow;
    background-image: none;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
    .columns {
        margin-bottom: 0;
        padding-bottom: 0;
        .column {
            margin-bottom: 0;
        }
    }
    button {
        padding: 5px 20px;
        border-radius: 5px;
    }
}
  
.noteBox {
    padding: 15px;
    border-radius: 10px;
    box-shadow: none;
    background-color: $yellow-light;
    margin-bottom: 20px;
    text-align: left;

    p {
        color: $yellow-dark;
        font-size: 12pt;

        &.infos {
            font-size: 10pt;
        }
    }

    h3 {
        color: $yellow-dark;
        font-size: 14pt;
    }

    .icon {
        border-radius: 100%;
        background-color: $yellow;
        
        h4 {
            font-size: 10px;
            color: $yellow-dark;
            text-transform: uppercase;
        }
    }
}

.todoBox {
    h3 {
        display: contents;
        font-size: 14pt;
    }
}

  
textarea {
    border: 1px solid #ced4da;
    width: 100%;
    border-radius: 0.4em;
    padding: 10px;
}
  
button {
    margin-right: 10px;
    &.todo {
        background-color: white;
        border-radius: 100%;
        width: 25px;
        height: 25px;
        padding: 5px;
        float: right;

        &:hover, .done {
            background-color: $green;
        }
        .done {
            &:hover{
                background-color: #A20021;
            }
        }
        img {
            .icon {
                margin-top: 2px;
                height: 12px;
                width: 20px;
            }
        }
    }
    &.active {
        background-color: $yellow-light;
    }
}
.form {
    input[type=text] {
        width: 100%;
        padding: 10px;
        border: 1px solid #ced4da;
        color: #495057;
        border-radius: 0.4em;
        font-size: 16px;
    }

    input[type=submit] {
        margin-top: 10px;
        padding: 5px 20px;
        width: auto;
        border-radius: 5px;
        border: none;
        background-color: #F4F4F4;
        font-size: 10pt;
        &:hover {
            color: white;
            background-color: $blue;
        }
    }

    input[type=button] {
        border: none;
    }
    select {
        width: 100%;
        font-size: 16px;
        height: 38px;
    }
}

form {
    button {
        margin-top: 5px;
        padding: 5px 20px;
        border-radius: 5px;
      }
} 

input[type=button]{
    &:hover {
        background-color: #A20021;
        color: white;
    }
}
  
.search {
    text-align: left;
    margin-left: 0;

    input[type=text] {
        width: 100%;
        padding: 5px;
        border: 1px solid #ced4da;
        color: #495057;
        border-radius: 0.4em;
        font-size: 12px;
    }

    input {
        padding: 5px;
        font-size: 14px;
    }
}
  
.optionListContainer {
    position: relative !important;
}

/* Tags */
  
.tag {
    border-radius: 3em;
    text-align: left;
    background-color: #61dafb;
    padding: 5px;
    font-weight: 700;

    &.active {
        background-color: $green-light;
        color: $green-font;
        p {
            color: $green-font;
            
        }
    }

    &.inactive {
        background-color: $yellow-light;
        color: $yellow-font;
        p {
            color: $yellow-font;
            
        }
    }

    &.failed {
        background-color: $red-light;
        color: $red;
        p {
            color: $red;
        }
    }

    p {
        font-size: 15px;
        font-weight: 700;
    }
}
  
button {
    //padding: 5px 20px;
    border: none;
    background-color: #F4F4F4;
    //border-radius: 5px;
    
    &:hover {
        color: white;
        background-color: $blue;
    }

    &.actions {
        float: right;
    }
}
  
.importantAction {
    background-color: #A20021;
    color: white;
}

.contactPerson {
    background-color: $yellow-light;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    a {
        color: $yellow-dark;
        background-color: $yellow;
        padding: 4px 10px;
        border-radius: 5px;
        &:hover{
            color: $yellow-light;
            background-color: $yellow-dark;
        }
    }

    .infos{
        margin-top: 10px;
        margin-bottom: 10px;
    }

    p {
        line-height: 1.2;
    }

    &.passive {
        background-color: $blue-light;
        a {
            color: $blue-dark;
            background-color: $blue;

            &:hover{
                color: $blue-light;
                background-color: $blue-dark;
            }
        }
    }
}

.contactSummary {
    a {
        color: $yellow-dark;
        background-color: $yellow;
        padding: 4px 10px;
        border-radius: 5px;
        &:hover{
            color: $yellow-light;
            background-color: $yellow-dark;
        }
    } 
}

.nameLine {
    display: flex;
}

.align-right-top {
    position: absolute;
    top: 15px;
    right: 15px;
}

.drag-drop {
    width: 100%;
    min-height: 100px;
    border: dashed 1px grey;
    border-radius: 10px;
    padding: 20px;
    &:hover {
        background-color: $blue-light;
    }
}
  
.align-right {
    text-align: right !important;
}
  
.is-borderless td {
    border: none !important;
}
  
.tableTop {
    margin-bottom: 50px;
    h2 {
        display: initial;
    }
}
  
.pagination {
    float: right;
    display: block;
    button {
        padding: 5px 10px;
        border-radius: 5px;
    }
}
  
.withoutMargin {
    margin-bottom: 0 !important;
}

.statements {
    td { white-space: pre-line;
        max-width: 250px; }
}

.myTable {
    
    thead {
        display: table-header-group;
        box-sizing: border-box;
        background-color: $blue-light;
        th {
            height: 60px;
            vertical-align: middle;
            align-items: center;
        }
        th:first-of-type {
            padding-left: 24px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }
        th:last-of-type {
            padding-right: 24px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        input {
            background-color: transparent;
            border: none;
            box-shadow: none;
            padding: 0;
            color: $blue-dark;
            font-size: 15px;
            &::placeholder {
                color: $blue-dark;
                font-weight: 700;
            }
        }


        select {
            border: none;
            background: $blue-light;
            color: $blue-dark;
            font-weight: 700;
            &:hover {
                background-color: $yellow-light;
                color: $yellow-font;
            }
        }
    }
    tbody {
        td {
            height: 60px;
            vertical-align: middle;
            align-items: center;
        }
        td:first-of-type {
            padding-left: 30px;
        }
        td:last-of-type {
            padding-right: 24px;
        }

        a {
            background-color: $blue-light;
            color: $blue;
            font-weight: 700;
            padding: 2px 5px;
            border-radius: 0.3em;
            &:hover {
                background-color: $blue;
                color: $blue-light;
            }
        }
    }

    &.lined {
        tr {
            border-bottom: 2px solid $grey-light;
        }
        tr:last-of-type {
            border-bottom: none;
        }
        td {
            vertical-align: initial;
        }
    }
    
    &.minimal {
        td {
            height: auto;
        }
        
    }
} 

.financeAccount {
    &.level-1 {
        td {
            font-size: 20px;
            font-weight: bold;
            a {
                font-weight: bold;
            }
        }
    }
    &.level-2 {
        td {
            font-size: 18px;
            font-weight: bold;
            a {
                font-weight: bold;
            }
        }
    }
    &.level-3 {
        td {
            font-size: 16px;
            font-weight: bold;
            a {
                font-weight: bold;
            }
        }
    }
    &.group {
        margin-top: 0;
        font-style: italic;
        a {
            font-style: italic;
        }
    }
}

a.boxLink {
    background-color: $yellow-light;
    border-radius: 10px;
    padding: 20px;
    font-size: 20px;
    font-weight: 700;
    color: $yellow-font;
    &:hover {
        background-color: $yellow-dark;
        color: $yellow-light;
    }
}

@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

.modal-background {
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
}

.modal-card {
    border-radius: 12px;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
}
  
div {
    &.burger {
        height: 30px; 
        width: 40px;
        position: fixed;
        top: 13px; 
        left: 21px;
        cursor: pointer;
        z-index: 9000;

        &.smaller {
            top: 5px;
        }
    }

    &.x, &.y, &.z {
        height: 3px; 
        width: 26px;
        position: absolute; margin: auto;
        top: 0px; bottom: 0px;		
        background: #000;
        border-radius:2px;
        -webkit-transition: all 200ms ease-out;
           -moz-transition: all 200ms ease-out;
            -ms-transition: all 200ms ease-out;
             -o-transition: all 200ms ease-out;
                transition: all 200ms ease-out;
    }
    
    &.y{
        top: 18px;
    }
    
    &.z{
        top: 37px;
    }

    &.collapse{
        top: 20px;
        background:#000;
        -webkit-transition: all 70ms ease-out;
           -moz-transition: all 70ms ease-out;
            -ms-transition: all 70ms ease-out;
             -o-transition: all 70ms ease-out;
                transition: all 70ms ease-out;
    }

    &.rotate30{
        -ms-transform: rotate(30deg); 
        -webkit-transform: rotate(30deg); 
        transform: rotate(30deg);	
        -webkit-transition: all 50ms ease-out;
           -moz-transition: all 50ms ease-out;
            -ms-transition: all 50ms ease-out;
             -o-transition: all 50ms ease-out;
                transition: all 50ms ease-out;					
    }

    &.rotate150{
        -ms-transform: rotate(150deg); 
        -webkit-transform: rotate(150deg); 
        transform: rotate(150deg);	
        -webkit-transition: all 50ms ease-out;
           -moz-transition: all 50ms ease-out;
            -ms-transition: all 50ms ease-out;
             -o-transition: all 50ms ease-out;
                transition: all 50ms ease-out;					
    }

    &.rotate45{
        -ms-transform: rotate(45deg); 
        -webkit-transform: rotate(45deg); 
        transform: rotate(45deg);	
        -webkit-transition: all 100ms ease-out;
           -moz-transition: all 100ms ease-out;
            -ms-transition: all 100ms ease-out;
             -o-transition: all 100ms ease-out;
                transition: all 100ms ease-out;					
    }

    &.rotate135{
        -ms-transform: rotate(135deg); 
        -webkit-transform: rotate(135deg); 
        transform: rotate(135deg);	
        -webkit-transition: all 100ms ease-out;
           -moz-transition: all 100ms ease-out;
            -ms-transition: all 100ms ease-out;
             -o-transition: all 100ms ease-out;
                transition: all 100ms ease-out;					
    }
}

.myNotification {
    min-width: 50vw;
    &button {
        width: auto;
        padding: inherit;
    }
}

  
html {
    background-color: #F4F4F4;
}
  
/*------------------------------------*\
    RESPONSIVE
\*------------------------------------*/
  
  
@media only screen and (min-width:320px) {
    
}
  
@media only screen and (max-width:480px) {
    .appHeader {
        a {
            &.add {
                text-align: right;
                width: fit-content;
                position: absolute;
                right: 28px;
                top: 20px;
                font-size: 20px;
            }
        }
        &.smaller {
            a.add {
                top: 15px;
            }
        }
    }

    .appHeaderInner {
        padding-top: 17px;
        &.smaller {
            padding-top: 10px;
        }
    }
    
}
  
@media only screen and (min-width:768px) {
    .appHeader {
        &.leftCorner {
            width: auto;
        }
        a {    
            &.add {
                font-size: 16px;
                padding: 6px 20px; 
                svg {
                    margin-right: 10px;
                    margin-top: 5px;
                }
            }
        }
    }

    .tableTop {
        margin-bottom: 20px;
    }
  
    button{
        &.actions {
            float: left;
        }
    }
  
    .table-with-input {
        max-width: 80vw;
    }
    .appHeaderInner {
        right: 218px;
    }

    .appHeaderSearch {
        position: fixed;
        top: 0;
        width: 100%;
        padding-top: 25px;
        padding-left: 0;
        padding-right: 20px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        z-index: 12;
    
    
        input {
            padding: 10px;
            border-radius: 0.4em;
            font-size: 16px;
        }
    
        * {
            margin-left: 10px;
        }
    }
    
    .datalist-input {
        * {
            margin-left: 0px;
        }
        width: calc(100% - 300px) !important;
    }
    
    .datalist-input-options {
        position: absolute;
        z-index: 10000;
        left: 267px;
    
        .datalist-input-option {
            padding: 10px;
            font-size: 17px;
        }
    }
}
  
@media only screen and (min-width:1024px) {
    .appHeader {
        &.leftCorner {
            width: auto;
        }
        a {    
            &.add {
                font-size: 16px;   
                svg {
                    margin-right: 10px;
                }
            }
        }
    }


    .appHeaderSearch {
        left: 217px;
    }

    .appHeaderInner {
        width: 100%;
        height: 80px;
        left: 217px;
        right: 0;

        &.maximized {
           left: 60px;
        }

        a {
            float: left;
        }
    }

    .appHeaderSearch {
        width: calc(100% - 200px);
    }

    .page {
        padding-left: 70px;
        padding-right: 50px;
    }

    .sideNavigation {
        display: block;
        height: 100%;
        width: auto;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        overflow-x: hidden;
        padding-top: 90px;
        padding-left: 20px;
    }
}
  
@media only screen and (min-width:1140px) {
    
}
  
@media only screen and (min-width:1280px) {
    
}

/*@media (prefers-color-scheme: dark) {
    html { filter: invert(95%); }
    img { filter: invert(100%); }
}*/

@media print {
    .navigation, .appHeader {
        display: none;
    }
}