body {
  text-align: left;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: #F4D9B5;*/
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, p {
  text-align: left;
}

h1, h2 {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
}

h1 {
  font-size: 30px;
  color: #2B2D42;
}

h3, h4, h5, h6, p, a, button {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
}

p {
  color: black;
  font-weight: 300;
  font-size: 16px;
}

h2 {
  color: #2B2D42;
  font-size: 30px;
}

h3 {
  color: #2B2D42;
  font-weight: 700;
  font-size: 22px;
}

h4 {
  color: #2B2D42;
  font-weight: 400;
  font-size: 20px;
}

h6 {
  color: #2B2D42;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 0px;
}

/*------------------------------------*\
    RESPONSIVE
\*------------------------------------*/

@media only screen and (min-width:320px) {

}

@media
  only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (   min--moz-device-pixel-ratio: 3),
  only screen and (     -o-min-device-pixel-ratio: 3/1),
  only screen and (        min-device-pixel-ratio: 3),
  only screen and (                min-resolution: 458dpi),
  only screen and (                min-resolution: 3dppx) { 

    /* iPhone X styles */

}

@media only screen and (min-width:480px) {

}

@media only screen and (min-width:768px) {
  h1 {
    font-size: 100px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 25px;
  }

  p {
    font-size: 17px;
  }
}

.align-left {
  text-align: left;
}

@media only screen and (min-width:1024px) {

  h1 {
    font-size: 35px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 25px;
  }
}