$red-dark: #660014;
$red: #A20021;
$red-light: #FFC2CE;

$green-dark: #1C2815;
$green: #56876D;
$green-font: #229a16;
$green-light: #54d62c29;

$blue-dark: #2B2D42;
$blue: #98C1D9;
$blue-light: #F0F6F9;

$yellow-dark: #362008;
$yellow-font: #b78103;
$yellow: #F2D0A9;
$yellow-light: #FAECDC;

$grey-light: #F4F4F4;